<template>
<div class="h-8 w-auto -mb-1 sm:mt-0 sm:h-10 pr-2 md:pr-0 logo-container flex">
<svg xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 314.19 328" class="logo-icon hidden md:block sm:h-8 w-auto">
  <defs>
    <linearGradient id="gradient-a" x1="340.12" y1="-32.48" x2="55.51" y2="294.45" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#8402ff" />
      <stop offset="0.20" stop-color="#8402ff" />
      <stop offset="0.55" stop-color="#f800dc" />
      <stop offset="1" stop-color="#ff0072" />
    </linearGradient>
  </defs>
  <path class="gradient-a" fill="url(#gradient-a)" d="M275.87,2.37,16.3,116.58a27.28,27.28,0,0,0-6.86,45.61L54.33,201a27.29,27.29,0,0,0,32.46,2.41c18.22-11.57,39.08-24.13,39.08-24.13l-33.54-29,148.42-48.69-75.23,62.21,30.07,26L34.44,295.43A2.41,2.41,0,0,0,36,299.84l119.66-11.27,37.91,32.77A27.28,27.28,0,0,0,237.69,308L313.17,34.61C319.29,12.44,296.92-6.89,275.87,2.37Z" />
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486 110.16" class="logo-text block h-7 sm:h-8 pt-1 px-1 sm:px-3 w-auto text-ghost-white">
<g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
  <path class="cls-1" fill="#fbfcff" d="M52.3,3.66H71V87.17H52.3V54H18.79V87.17H0V3.66H18.79V36.35H52.3Z"/>
  <path class="cls-1" fill="#fbfcff" d="M123.05,25.47h19.74l-34.46,84.69H89.42l9.69-23.82L74.41,25.47H94.62l14.3,39.18Z"/>
  <path class="cls-1" fill="#fbfcff" d="M181.5,24a27.72,27.72,0,0,1,21.31,9.25q8.48,9.25,8.48,23t-8.48,23.08a27.65,27.65,0,0,1-21.31,9.28,23.75,23.75,0,0,1-18.61-8.39v29.9H145.22V25.47h17.67v6.86A23.8,23.8,0,0,1,181.5,24ZM167.17,67.2a15.6,15.6,0,0,0,21.66,0,14.9,14.9,0,0,0,4.32-10.91,14.78,14.78,0,0,0-4.32-10.87,15.66,15.66,0,0,0-21.66,0,14.83,14.83,0,0,0-4.28,10.87A15,15,0,0,0,167.17,67.2Z"/>
  <path class="cls-1" fill="#fbfcff" d="M280,56.56a23.87,23.87,0,0,1-.48,5.43H232.33a15.31,15.31,0,0,0,5.41,8.22,14.93,14.93,0,0,0,9.37,3A17.39,17.39,0,0,0,255,71.48a11.91,11.91,0,0,0,5.08-4.4h18.73a30.79,30.79,0,0,1-12,15.63,34.5,34.5,0,0,1-20,5.94q-14.3,0-23.81-9.28a30.92,30.92,0,0,1-9.52-23.08,30.82,30.82,0,0,1,9.52-23Q232.45,24,246.75,24t23.73,9.25Q280,42.49,280,56.56ZM246.75,39.24a14,14,0,0,0-8.95,3,16.1,16.1,0,0,0-5.35,8H261a14.44,14.44,0,0,0-14.25-11Z"/>
  <path class="cls-1" fill="#fbfcff" d="M321.69,24q10.65,0,17.14,6.42t6.5,17.05V87.17H327.66V53.43a12.35,12.35,0,0,0-3.28-9,11.42,11.42,0,0,0-8.6-3.4,12.81,12.81,0,0,0-9.24,3.43A12.09,12.09,0,0,0,303,53.66V87.17H285.29V0H303V32.68A23.21,23.21,0,0,1,321.69,24Z"/>
  <path class="cls-1" fill="#fbfcff" d="M393.09,25.47h17.67v61.7H393.09v-6.8q-6.79,8.22-18,8.28-10.29,0-16.52-6.35t-6.24-16.94V25.47H370V60.05a11.56,11.56,0,0,0,3,8.36,10.6,10.6,0,0,0,8,3.16,11.8,11.8,0,0,0,8.66-3.28,11.35,11.35,0,0,0,3.34-8.48Z"/>
  <path class="cls-1" fill="#fbfcff" d="M456.21,24a27.71,27.71,0,0,1,21.31,9.25q8.48,9.25,8.48,23t-8.48,23.08a27.63,27.63,0,0,1-21.31,9.28,23.75,23.75,0,0,1-18.61-8.39v6.91H419.93V0H437.6V32.33A23.8,23.8,0,0,1,456.21,24ZM441.88,67.2a15.6,15.6,0,0,0,21.66,0,14.9,14.9,0,0,0,4.32-10.91,14.78,14.78,0,0,0-4.32-10.87,15.66,15.66,0,0,0-21.66,0,14.83,14.83,0,0,0-4.28,10.87A15,15,0,0,0,441.88,67.2Z"/>
  </g></g>
</svg>
</div>
</template>

<script>
export default {
    name: 'Logo',
}
</script>