require('./bootstrap');
require('./lazy-load');

import { createApp, h } from 'vue';
import { createInertiaApp, Link } from '@inertiajs/vue3';
import VueObserveVisibility from 'vue-observe-visibility';
import VueToast from 'vue-toast-notification';
import VueCroppie from 'vue-croppie';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-default.css';

import Toast, {POSITION} from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

createInertiaApp({
  progress: {
    color: '#8401FF',
  },
  resolve: name => import(`./Pages/${name}`),
  setup({ el, App, props, plugin }) {
    const app =createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(VueObserveVisibility)
      .use(VueToast)
      .use(Toast, {
        position: POSITION.TOP_CENTER,
        toastClassName: "hypehub-toast",
      })
      .use(VueCroppie)
      .mixin({ methods: { route } })
      .component('app-logo', require('@/Shared/Logo.vue'))
      .component('Link', Link)
      .mount(el);
  },
});
